<template>
 <CCard>
  <CCardBody>
    <!-- <div class="clearfix">
      <button type="button" class="btn btn-secondary float-xl-right" @click="navToNewSymptoms">New</button>
    </div> -->

    <CDataTable  :items="items"
        :fields="fields"
         striped
      clickable-rows
        column-filter
        table-filter
        items-per-page-select
        :items-per-page="20"
        sorter
        ref="vuetable"
        pagination>

       <template #isacknowledged-filter>
          <select
            class="form-control form-control-sm"
            @input="$refs.vuetable.columnFilterEvent('isacknowledged', $event.target.value, 'input')"
          >
            <option value selected="selected">Any</option>

            <option value="1"> Acknowledged </option>
            <option value="2">Not Acknowledged</option>
            <option value="3">Attending</option>
          </select>
        </template>


          
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
<template #firstName="{item}">
        <td class='font-weight-bold'
          v-if="item.firstName && item.lastName"
        >{{ item.firstName }} {{item.lastName }}</td>
        <td v-else>--</td>
     </template>
      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
      <template #reasonIfNotAck="{item}">
        <td class='font-weight-bold'
          v-if="item.reasonIfNotAck"
        >{{ item.reasonIfNotAck  }}</td>
        <td v-else>--</td>
      </template>
       <!-- <template #isacknowledged="{item}">
        <td  v-show="item.isacknowledged== 0">
           Not Acknowledged  </td>
         
          <td
          v-show="item.isacknowledged== 1">
            Acknowledged  </td>
            <td
          v-show="item.isacknowledged == 2">
            Not Acknowledged</td>
            <td
          v-show="item.isacknowledged == 3">
            Attending  </td>   
            
            
        <td
          v-if="item.isacknowledged"
        >{{ item.isacknowledged  }}</td>
        <td v-else>--</td>
      </template>  -->
       <!-- <template #isacknowledged="{item}">
         <td
          v-if="item.isacknowledged"
        >{{ item.isacknowledged  }}</td>
        <td v-else>--</td>
      </template> -->

        <template #isAcknowledged="{ item }">
          <td class='font-weight-bold' v-show="item.isAcknowledged == 0">Not Acknowledged</td>
          <td class='font-weight-bold' v-show="item.isAcknowledged == 1">Acknowledged</td>
          <td class='font-weight-bold' v-show="item.isAcknowledged == 2">Not Acknowledged</td>
           <td class='font-weight-bold' v-show="item.isAcknowledged == 3"> Attending </td>
        </template> 
      <!-- <template #isacknowledged="{ item }">
          <td v-show="item.isacknowledged == '1'">Acknowledged </td>
          <td v-show="item.isacknowledged == '2'">Not Acknowledged</td>
        </template> -->
       <!-- <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
              
           <CDropdownItem :to="{name: 'Acknowledgement', params: {acknowledgeId: item.acknowledgeId, mode: 'view'}}">View</CDropdownItem>
           
                
                </CDropdown>

        
        </td>
      </template> -->
    
      </CDataTable>
  </CCardBody>
   <CCardFooter align="right">
        
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
</template>

<script>
const fields = [
  { key: 'index',_style: "min-width:30px",_classes: 'font-weight-bold', },
  { key: "firstName",label: "Technician", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "isAcknowledged", label: "Acknowledged Status",_classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "reasonIfNotAck", label: "Reason If Not Acknowledged",_classes: 'font-weight-bold', _style: "min-width:200px" },
 
  { key: "createdTime", label: "Created Date & Time",_classes: 'font-weight-bold', _style: "min-width:200px" },
  // { key: "show_details",
  //   label: "",
  //   _style: "width:1%",
  //   sorter: false,
  //   filter: false,
  // },
];
export default {
  name: "Acknowledgements",
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getSymptoms() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/acknowledges/"+this.$route.params.briefingId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
    navToNewSymptoms(){
       this.$router.push({ name: "Acknowledgement", params: { mode: "new" } });
    },
     cancel() {
      
        this.$router.push({ name: "Briefings" });
    },
  },
  mounted() {
    this.getSymptoms();
  },
};
</script>